import React, {useState} from 'react'
import axios from 'axios';
import conf from '../../conf'

const Notification = ()=>{
     const [isSending, setIsSending] = useState(false)
     const [formData, setFormData] = useState({
          title: '',
          body: '',
          link: '',
          file: '',
     })

     const onSubmit = async ()=>{
          setIsSending(true)
          try {
               const rBody = new FormData();
               rBody.append('title', formData.title)
               rBody.append('message', formData.body)
               rBody.append('link', formData.link)
               rBody.append('file', formData.file)
               const res = await axios.post(conf.serverAddress + "admin/push-notification", rBody, 
                    {headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
               }})
               console.log(res);
               setFormData({
                    title: '',
                    body: '',
                    link: '',
                    file: '',
               })
               setIsSending(false)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               setFormData({
                    title: '',
                    body: '',
                    link: '',
                    file: '',
               })
          }
     }

     const onChangeForm = (event)=>{
          const { value, name } = event.target;
          setFormData(pVal => {
               if(name != 'file'){
                    return {
                         ...pVal,
                         [name]: value
                    };
               }
               else{
                    return {
                         ...pVal,
                         file: event.target.files[0]
                    };
               }
          })
     }

     return(
          <div style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               flexDirection: 'column'
          }}>
               <h3 style={{width: '500px', marginBottom: '20px'}}>Notification</h3>
               <textarea name='title' onChange={onChangeForm} style={{ width:'500px', border: 'none', padding: '10px 20px', borderRadius: '5px' }} 
                    placeholder="title..." 
                    maxLength={50}
                    value={formData.title}/>
               <input name='link' onChange={onChangeForm} style={{ width:'500px', border: 'none', padding: '10px 20px', borderRadius: '5px', marginTop: '15px' }} 
                    placeholder="link..." 
                    value={formData.link}/>
               <textarea name='body' onChange={onChangeForm} style={{ width:'500px', height:'100px', border: 'none', padding: '10px 20px', borderRadius: '5px', marginTop: '15px' }} 
                    placeholder="message..." 
                    maxLength={100}
                    value={formData.body}/>
               <h5 style={{width: '500px', marginTop: '10px'}}>Image</h5>
               <div style={{width: '500px'}}>
                    { formData.file ? <div  style={{width: '200px', height: '100px', background: '#cdd3d4', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <img src={URL.createObjectURL(formData.file)} style={{maxWidth: '200px', height: '100px'}}/> 
                         </div> : 
                    <div style={{width: '200px', height: '100px', background: '#cdd3d4', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                         Image
                    </div> }
                    <input name='file' type='file' accept='image/*' onChange={onChangeForm} style={{marginTop: '10px'}}/>
               </div>    
               <button onClick={onSubmit} className="badge badge-gradient-success"  style={{
                         width: '500px', display: 'flex', justifyContent: 'center',
                         border: 'none', borderRadius: '5px', margin: '15px 0', fontSize: '16px',
                         padding: '10px 0', cursor: isSending ? 'wait' : 'pointer' 
                    }}>Send</button>
          </div>
     );
}
export default Notification;