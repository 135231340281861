import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import './style.css';
import {ArrowBackIosOutlined, ArrowForwardIosRounded} from '@material-ui/icons'
import UserDataItem from '../items/UserDataItem'
import conf from '../../conf'
import NetworkItem from '../items/network_item';

const Network = ()=>{
     const id = new URLSearchParams(useLocation().search).get("id");
     const [isNoMoreUsers, setNoMoreUsers] = useState(false);
     const [page, setPageIndex] = useState(0);
     const [users, setUsers] = useState([]);
     const [message, setMessage] = useState("Loading...");
     const [level, setLevel] = useState("level_1")

     const onPageChange = async (index, isNext, level)=>{
          setLevel(level);
          try {
               setMessage('Loading...')
               const params = new URLSearchParams();
               params.append("skip", 20 * index);
               params.append("limit", 20);
               params.append("level", level);
               params.append("id", id);
               const res = await axios.get(conf.serverAddress + "admin/user-level",
                    {params: params, headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}});
               if(res.data.length != 0){
                    setUsers(res.data);
                    setMessage('')
                    setNoMoreUsers(false)
                    if(isNext){
                         setPageIndex(index);
                    }
                    else{
                         setPageIndex(index);
                    }
               }    
               else{
                    setMessage('Empty!');
               } 
               if(res.data.length < 20){
                    setNoMoreUsers(true);
               }
          } 
          catch (error) {
               setMessage('Failed! try again')
          }
     }

     const buttonStyle = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px',
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)"};

     const buttonStyleDesable = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px', opacity: 0.2,
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)", };

     useEffect( async ()=>{ 
          onPageChange(0, true, 'level_1');
     }, []);

     return(
          <>
               <div className="page-header">
                    <h3>Network</h3>
                    <select onChange={(e)=> onPageChange(0, true, e.target.value)} className='status-select'>
                         <option value="level_1">Level 1</option>
                         <option value="level_2">Level 2</option>
                         <option value="level_3">Level 3</option>
                         <option value="level_4">Level 4</option>
                         <option value="level_5">Level 5</option>
                         <option value="level_6">Level 6</option>
                         <option value="level_7">Level 7</option>
                    </select>
               </div>
               
               <div className="row">
                    <div className="col-12 grid-margin">
                         <div className="card">
                              <div className="card-body">
                                   <div className="table-responsive">
                                        <table className="table">
                                             <thead>
                                                  <tr>
                                                       <th>No</th>
                                                       <th>User</th>
                                                       <th>Balance</th>
                                                       <th>Status</th>
                                                       <th>Member</th>
                                                       <th>Pending</th>
                                                       <th>Mobile Number</th>
                                                       <th>Email</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {
                                                       message === "" ? users.map((arr, i) => <NetworkItem no={((page*20)+i)+1} ele={arr}/>) : null
                                                  }
                                             </tbody>
                                        </table>
                                        {
                                             message === "" ? null : <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  margin: '100px 0'
                                             }}>{message}</div>
                                        }
                                        <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'end',
                                                  padding: '10px',
                                             }}>
                                                  <div 
                                                       style={{
                                                            borderRadius: '5px',
                                                            // border: '1px solid red'
                                                       }}>
                                                       <ArrowBackIosOutlined className=" bg-gradient-primary" 
                                                            style={page !== 0 ? buttonStyle : buttonStyleDesable}
                                                            onClick={()=>{
                                                                 if(page > 0){
                                                                      onPageChange(page - 1, false, level)
                                                                 }
                                                            }}
                                                       />
                                                       <span style={{margin: "0 15px"}}>{page + 1}</span>
                                                       <ArrowForwardIosRounded className=" bg-gradient-primary"
                                                            style={isNoMoreUsers ? buttonStyleDesable : buttonStyle}
                                                            onClick={()=>{
                                                                 if(!isNoMoreUsers){
                                                                      onPageChange(page + 1, true, level)
                                                                 }
                                                            }}
                                                            />
                                                  </div>
                                             </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default Network;