import React from "react";
import './style.css'

const HistoryItem = (props)=>{

     const { name, profilePhoto, amount, paytmNumber, date, id } = props.ele;
     const actionEnable = props.actionEnable;
     
     return(
          <tr>
               <td>{props.id}</td>
               <td>
                   <img src={profilePhoto ? profilePhoto : "assets/images/faces/user.png"} className="mr-2" alt="pic"/>
                    {actionEnable ?  <a className="ax" href={"admin-user-data?id="+id}>{name}</a> : name}
               </td>
               <td>{ "₹" + Number(amount).toFixed(2) }</td>
               <td>+91-{paytmNumber}</td>
               <td>{date}</td>
          </tr>
     );
}

export default HistoryItem;