import React, {useState, useEffect} from 'react'
import axios from 'axios';
import conf from '../../conf'
import {useLocation} from "react-router-dom"

const UserPage = ()=>{
     const [isSending, setIsSending] = useState(false);
     const [user, setUser] = useState({});
     const [message, setMessage] = useState("");
     const params = new URLSearchParams(useLocation().search);
     const id = params.get("id")

     const getUser = async ()=>{
          setMessage("Loading...")
          try {
               const res = await axios.get(conf.serverAddress + "admin/user", {
                    params: params,
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setUser(res.data)
               setMessage("")
          } catch (err) {
               setUser(null);
               setMessage("Failed!")
          }
     }

     useEffect(()=>{ getUser(); }, []);

     const onSubmit = async (json)=>{

          setIsSending(true)
          try {
               const rBody = new FormData();
               console.log(json);
               if(json.coins){
                    rBody.append('coins', json.coins);
               }
               
               if(json.accountStatus){
                    rBody.append('accountStatus', json.accountStatus);
               }
               rBody.append('id', id);
               await axios.patch(conf.serverAddress + "admin/user", rBody, 
                    {headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
               }})
               setIsSending(false)
               window.location.reload(false);
          } catch (error) {
               console.log(error);
               setIsSending(false);
               setUser(null)
               setMessage("Failed!")
          }
     }

     const sCenter = {
          display: 'flex',
          justifyContent: "center",
          alignItems:'center'
     };

     return(
         message!=="" ?  <h3 style={{textAlign: 'center', marginTop: '150px'}}>Loading...</h3> :

         <div style={{display: 'flex', justifyContent: "center"}}>
               <div className="card" style={{width: '500px', padding: "20px 25px"}}>

                    <div style={{...sCenter, flexDirection: 'column', marginBottom: "20px"}}>
                         <img src={user.profilePhoto ? user.profilePhoto : "assets/images/faces/user.png"} style={{height: '100px', width: '100px', borderRadius: "50px"}} />
                         <span style={{fontSize: '20px', marginTop: '10px'}}>{user.name}</span>
                    </div>
                    

                    <Lebel name="Balance" value={"₹"+user.balance} />
                    <div style={{marginTop: '10px'}}>
                         Coins :<input type="number" min={0} max={999999} value={user.coins} style={{marginLeft: '10px', width: '100px'}} 
                         onChange={(e)=>setUser({...user, coins: e.target.value})} /><br/>
                    </div>
                    <Lebel name="Mobile Number" value={"+91-"+user.mobileNumber} />
                    <Lebel name="Email Address" value={user.email} />
                    <Lebel name="Account Status" value={user.accountStatus} />
                    <Lebel name="Pending" value={user.pendingChilds} />
                    <Lebel name="Member" value={user.totalChilds} />
                    <Lebel name="Join At" value={new Date(user.date).toDateString()} />

                    <Lebel name="Withdrawal History" value={<a href={"/admin-user-withdrawal-history?id="+ user._id}>View</a>} />
                    <Lebel name="Network" value={<a href={"/admin-user-network?id="+ user._id}>View</a>} />

                    <div style={{...sCenter}}>
                         <button onClick={()=>{
                              onSubmit({
                                   accountStatus: user.accountStatus !== "blocked" ? "blocked" : "active"
                              })
                         }} className="badge badge-gradient-danger"  style={{
                              width: '200px', display: 'flex', justifyContent: 'center',
                              border: 'none', borderRadius: '5px', fontSize: '16px',
                              padding: '10px 0', cursor: isSending ? 'wait' : 'pointer' ,
                              marginTop: '30px'
                         }}>{user.accountStatus!=="blocked"?"Block this user":"Unblock this user"}</button>
                              <span style={{width: "40px"}}/>
                         <button onClick={()=>{
                              onSubmit({
                                   coins: user.coins
                              })
                         }} className="badge badge-gradient-success"  style={{
                              width: '200px', display: 'flex', justifyContent: 'center',
                              border: 'none', borderRadius: '5px', fontSize: '16px',
                              padding: '10px 0', cursor: isSending ? 'wait' : 'pointer' ,
                              marginTop: '30px'
                         }}>Save coins</button>
                    </div>
               </div>
          </div>
     );
}

const Lebel = (props) => {
     return (
          <div style={{marginTop: '15px'}}>
               {props.name} :<span style={{marginLeft: '10px'}}> {props.value}</span><br/>
          </div>
     );
}
export default UserPage;