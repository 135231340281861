import conf from "../../conf";
import React from "react";
import axios from "axios";

const PaymentItem = (props)=>{

     const { name, profilePhoto, amount, date, email, user, mobileNumber } = props.ele;
     
     return(
          <tr>
               <td>{props.id}</td>
               <td>
                   <img src={profilePhoto ? profilePhoto : "assets/images/faces/user.png"} className="mr-2" alt="pic"/>
                   <a className="ax" href={"admin-user-data?id="+user}>{name}</a>
               </td>
               <td>{ "₹" + Number(amount).toFixed(2) }</td>
               <td>{  email || "NONE" }</td>
               <td>{  mobileNumber || "NONE" }</td>
               <td>{new Date(date).toDateString()}</td>
          </tr>
     );
}

export default PaymentItem;