import React from "react";

const Home = (props)=>{
     return(
          <div className="container-scroller">
               <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                         <a className="navbar-brand brand-logo" href="/admin-dashboard"><img src="assets/images/admin.png" alt="logo" /></a>
                         <a className="navbar-brand brand-logo-mini" href="/admin-dashboard"><img src="assets/images/admin-logo.png" alt="logo" /></a>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-stretch">
                         <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                    <span className="mdi mdi-menu"></span>
                    </button>
                         <ul className="navbar-nav navbar-nav-right">
                              <li className="nav-item d-none d-lg-block full-screen-link">
                                   <a className="nav-link">
                                        <i className="mdi mdi-fullscreen" id="fullscreen-button"></i>
                                   </a>
                                   </li>
                              {/* <li className="nav-item nav-logout d-none d-lg-block" onClick={()=>{
                                             window.open(window.location, '_self').close();
                                        }}>
                                   <a className="nav-link">
                                        <i className="mdi mdi-power"></i>
                                   </a>
                              </li> */}
                              <li className="nav-item nav-settings d-none d-lg-block">
                                   <a className="nav-link" href="#">
                                        <i className="mdi mdi-format-line-spacing"></i>
                                   </a>
                              </li>
                         </ul>
                         <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                    <span className="mdi mdi-menu"></span>
                    </button>
                    </div>
               </nav>
               
               <div className="container-fluid page-body-wrapper">
               
                    <nav className="sidebar sidebar-offcanvas" id="sidebar">
                         <ul className="nav">     
                              <li className="nav-item">
                                   <a className="nav-link" href="/admin-dashboard" >
                                        <span className="menu-title">Dashboard</span>
                                        <i className="mdi mdi-home menu-icon"></i>
                                   </a>
                              </li>
                              <li className="nav-item">
                                   <a className="nav-link" href="/admin-upload-banner">
                                        <span className="menu-title">Upload banner</span>
                                        <i className="mdi mdi-upload menu-icon"></i>
                                   </a>
                              </li>
                              <li className="nav-item">
                                   <a className="nav-link" href="/admin-withdrawal-history">
                                        <span className="menu-title">Withdrawal history</span>
                                        <i className="mdi mdi-bank menu-icon"></i>
                                   </a>
                              </li>
                              <li className="nav-item">
                                   <a className="nav-link" href="/admin-payment-history">
                                        <span className="menu-title">Payment history</span>
                                        <i className="mdi mdi-credit-card menu-icon"></i>
                                   </a>
                              </li>
                              <li className="nav-item">
                                   <a className="nav-link" href="/admin-notification">
                                        <span className="menu-title">Notification</span>
                                        <i className="mdi mdi-bell menu-icon"></i>
                                   </a>
                              </li>
                              <li className="nav-item">
                                   <a className="nav-link" href="/admin-setting">
                                        <span className="menu-title">Setting</span>
                                        <i className="mdi mdi-settings menu-icon"></i>
                                   </a>
                              </li>
                         </ul>
                    </nav>
                    
                    <div className="main-panel">
                         <div className="content-wrapper">
                              {props.children}
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default Home;