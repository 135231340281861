import React, {useEffect, useState} from 'react'
import axios from 'axios';
import {ArrowBackIosOutlined, ArrowForwardIosRounded, SearchRounded} from '@material-ui/icons'
import conf from '../../conf'
import PaymentItem from '../items/PaymentItem';

const PaymentHistory = ()=>{

     const [isNoMoreUsers, setNoMoreUsers] = useState(false);
     const [page, setPageIndex] = useState(0);
     const [users, setUsers] = useState([]);
     const [message, setMessage] = useState("Loading...");
     const [search, setSearch] = useState("");

     const onPageChange = async (index, isNext, searchQuery)=>{
          try {
               setMessage('Loading...')
               const params = new URLSearchParams();
               params.append("skip", 20 * index);
               params.append("limit", 20);
               if(searchQuery){
                    params.append("searchQuery", searchQuery);
               }
               const res = await axios.get(conf.serverAddress + "admin/payment-history", 
               {params: params, headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}});
               if(res.data.length != 0){
                    setUsers(res.data);
                    setMessage('')
                    setNoMoreUsers(false)
                    if(isNext){
                         setPageIndex(index);
                    }
                    else{
                         setPageIndex(index);
                    }
               }    
               else{
                    setMessage('Empty!');
               } 
               if(res.data.length < 20){
                    setNoMoreUsers(true);
               }
          } 
          catch (error) {
               setMessage('Failed! try again')
          }
     }

     const onSearch = (query)=>{
          onPageChange(0, true, query);
     }

     const buttonStyle = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px',
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)"};

     const buttonStyleDesable = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px', opacity: 0.2,
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)", };

     useEffect( async ()=>{ 
          onPageChange(0, true);
     }, []);

     return(
          <>
               <div className="page-header" style={{display: 'flex', justifyContent: "space-around"}}>
                    <h3> Payment history </h3>
                    <div style={{padding: "10px 20px",borderRadius: "5px", background: "#FFF", display:"flex", alignItems: "center"}} >
                         <input className='input_n' value={search} 
                              onChange={(e)=>setSearch(e.target.value)} 
                              placeholder="Search by number"
                              style={{border:'none'}}
                              type="number"
                              />
                         <SearchRounded style={{width: "20px"}} onClick={()=>{onSearch(search)}} />
                    </div>
               </div>

               <div className="row">
                    <div className="col-12 grid-margin">
                         <div className="card">
                              <div className="card-body">
                                   {/* <h4 className="card-title">Recent Tickets</h4> */}
                                   <div className="table-responsive">
                                        <table className="table">
                                             <thead>
                                                  <tr>
                                                       <th>No</th>
                                                       <th>User</th>
                                                       <th>Amount</th>
                                                       <th>Email</th>
                                                       <th>Moblie number</th>
                                                       <th>Date</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {
                                                       message === "" ? users.map((arr, i) => <PaymentItem id={((page*20)+i)+1} ele={arr}/>) : null
                                                  }
                                             </tbody>
                                        </table>
                                        {
                                             message === "" ? null : <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  margin: '100px 0'
                                             }}>{message}</div>
                                        }
                                        <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'end',
                                                  padding: '10px',
                                             }}>
                                                  <div 
                                                       style={{
                                                            borderRadius: '5px',
                                                            // border: '1px solid red'
                                                       }}>
                                                       <ArrowBackIosOutlined className=" bg-gradient-primary" 
                                                            style={page !== 0 ? buttonStyle : buttonStyleDesable}
                                                            onClick={()=>{
                                                                 if(page > 0){
                                                                      onPageChange(page - 1, false)
                                                                 }
                                                            }}
                                                       />
                                                       <span style={{margin: "0 15px"}}>{page + 1}</span>
                                                       <ArrowForwardIosRounded className=" bg-gradient-primary"
                                                            style={isNoMoreUsers ? buttonStyleDesable : buttonStyle}
                                                            onClick={()=>{
                                                                 if(!isNoMoreUsers){
                                                                      onPageChange(page + 1, true)
                                                                 }
                                                            }}
                                                            />
                                                  </div>
                                             </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default PaymentHistory;