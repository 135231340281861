import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import conf from '../../conf'
import {Switch} from '@mui/material'
import {useToasts} from 'react-toast-notifications'

const Setting = ()=>{

     const {addToast} = useToasts();

     const defultValue = {
          spinVisible: true,
          scratchCardVisible: true,
          withdrawalEnable: true,
          withdrawalVisible: true,
          withdrawalHideMessage: "",
          appDownloadLink: "",
          forceUpdate: false,
          versionCode: 1,
          appId: "",
          interstitialAdId: "",
          rewardedAdId: "",
          invitationMessage: "",
          referalImage: "",

          withdrawalLimit: 100,
          helpLink: "",
          helpVideo: "",
          privacyPolicy: "",
          termsConditions: "",
          upiId: ""
     };

     const [file, setfile] = useState(null);
     const [video, setvideo] = useState(null);
     const [isSending, setIsSending] = useState(false);
     const [formData, setFormData] = useState(defultValue);

     const getSetting = async ()=>{
          try {
               const res = await axios.get(conf.serverAddress + "admin/setting", {
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setFormData(res.data)
          } catch (err) {
               console.log(err);
          }
     }

     useEffect(()=>{ getSetting(); }, [])

     const onSubmit = async ()=>{

          setIsSending(true)
          try {
               const rBody = new FormData();
               rBody.append('spinVisible', formData.spinVisible);
               rBody.append('scratchCardVisible', formData.scratchCardVisible);
               rBody.append('withdrawalEnable', formData.withdrawalEnable);
               rBody.append('withdrawalVisible', formData.withdrawalVisible);
               rBody.append('withdrawalHideMessage', formData.withdrawalHideMessage);
               rBody.append('appDownloadLink', formData.appDownloadLink);
               rBody.append('forceUpdate', formData.forceUpdate);
               rBody.append('versionCode', formData.versionCode);

               rBody.append('appId', formData.appId);
               rBody.append('interstitialAdId', formData.interstitialAdId);
               rBody.append('rewardedAdId', formData.rewardedAdId);
               rBody.append('invitationMessage', formData.invitationMessage);

               rBody.append('withdrawalLimit', formData.withdrawalLimit);
               rBody.append('helpLink', formData.helpLink);
               rBody.append('privacyPolicy', formData.privacyPolicy);
               rBody.append('termsConditions', formData.termsConditions);
               rBody.append('upiId', formData.upiId);

               if(file){
                    rBody.append('file', file);
               }
               if(video){
                    rBody.append('helpVideo', video);
               }

               await axios.patch(conf.serverAddress + "admin/setting", rBody, 
                    {headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
               }})
               setIsSending(false)
               addToast("Changes saved successfully", {
                    appearance: 'success',
                    autoDismiss: true,
                  })
          } catch (error) {
               console.log(error);
               setIsSending(false)
               setFormData(defultValue)
               addToast("Failed", {
                    appearance: 'error',
                    autoDismiss: true,
                  })
          }
     }

     const vBox = {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
     };

     const sInput = {width: "440px", marginTop: "10px", border: '1px solid #8a8a8a', borderRadius: "5px", padding: '10px 15px'};

     return(
          <div style={{display: 'flex', justifyContent: "center"}}>
               <div className="card" style={{width: '500px', padding: "20px 25px"}}>
                    <h3>Setting</h3>
                    <div style={vBox}>
                         Spin banner visibility <Switch checked={formData.spinVisible} onChange={(e,isChecked)=>setFormData({
                              ...formData,
                              spinVisible: isChecked
                         })} />
                    </div>
                    <div style={vBox}>
                         Scratch card banner visibility <Switch checked={formData.scratchCardVisible} onChange={(e,isChecked)=>setFormData({
                              ...formData,
                              scratchCardVisible: isChecked
                         })} />
                    </div>
                    <div style={vBox}>
                         Withdrawal button visibility <Switch checked={formData.withdrawalVisible} onChange={(e,isChecked)=>setFormData({
                              ...formData,
                              withdrawalVisible: isChecked
                         })} />
                    </div>
                    <div style={vBox}>
                         Force update <Switch checked={formData.forceUpdate} onChange={(e, is)=>setFormData({
                                   ...formData,
                                   forceUpdate: is
                              })} />
                    </div>
                    <div style={{marginTop: '15px'}}>
                         App version code <br />
                         <input value={formData.versionCode} type="number" min={1} style={sInput} onChange={e=> setFormData({
                              ...formData,
                              versionCode: e.target.value
                         })} placeholder="Version code..." />
                    </div>
                    <div style={{marginTop: '15px'}}>
                         App download link <br />
                         <input value={formData.appDownloadLink} type="url" style={sInput} onChange={e=> setFormData({
                              ...formData,
                              appDownloadLink: e.target.value
                         })} placeholder="Link..." />
                    </div>
                    <div style={{marginTop: '15px'}}>
                         UPI ID <br />
                         <input value={formData.upiId} type="url" style={sInput} onChange={e=> setFormData({
                              ...formData,
                              upiId: e.target.value
                         })} placeholder="id..." />
                    </div>
                    <div style={{...vBox, marginTop: '10px'}}>
                         Withdrawal ({formData.withdrawalEnable ? "Enable" : "Disabled"}) <Switch checked={formData.withdrawalEnable} onChange={(e,isChecked)=>setFormData({
                              ...formData,
                              withdrawalEnable: isChecked
                         })} />
                    </div>
                    <div style={{marginTop: '15px'}}>
                         Withdrawal limit <br />
                         <input value={formData.withdrawalLimit} type="number" min={1} style={sInput} onChange={e=> setFormData({
                              ...formData,
                              withdrawalLimit: e.target.value
                         })} placeholder="limit..." />
                    </div>
                    <div style={{marginTop: '10px'}}>
                         Message <br />
                         <textarea value={formData.withdrawalHideMessage} type="url" style={{...sInput, height: '150px'}} onChange={e=> setFormData({
                              ...formData,
                              withdrawalHideMessage: e.target.value
                         })} placeholder="message..." />
                    </div>

                    {/* ads control */}
                    <h4 style={{marginTop: '15px'}}>Ads</h4>
                    <div>
                         App id <br />
                         <input value={formData.appId} type="url" style={sInput} onChange={e=> setFormData({
                              ...formData,
                              appId: e.target.value
                         })} placeholder="App id..." />
                    </div>
                    <div style={{marginTop: '15px'}}>
                         Rewarded ad id <br />
                         <input value={formData.rewardedAdId} type="url" style={sInput} onChange={e=> setFormData({
                              ...formData,
                              rewardedAdId: e.target.value
                         })} placeholder="Rewarded ad id..." />
                    </div>
                    <div style={{marginTop: '15px'}}>
                         Interstitial ad id <br />
                         <input value={formData.interstitialAdId} type="url" style={sInput} onChange={e=> setFormData({
                              ...formData,
                              interstitialAdId: e.target.value
                         })} placeholder="Interstitial ad id..." />
                    </div>

                    {/* referal */}
                    <h4 style={{marginTop: '15px'}}>Referral</h4>
                    <div>
                         Invitation message <br />
                         <textarea value={formData.invitationMessage} type="url" style={{...sInput, height: '150px'}} onChange={e=> setFormData({
                              ...formData,
                              invitationMessage: e.target.value
                         })} placeholder="message..." />
                    </div>
                    <label style={{width: '500px', marginTop: '10px'}}>Referral image</label>
                    <div style={{width: '500px'}}>
                         { (file || formData.referalImage) ? <div style={{width: '200px', height: '100px', background: '#cdd3d4', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                   <img src={file ? URL.createObjectURL(file) : formData.referalImage} style={{maxWidth: '200px', height: '100px'}}/> 
                              </div> : 
                         <div style={{width: '200px', height: '100px', background: '#cdd3d4', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                         Image
                         </div> }
                         <input name='file' type='file' accept='image/jpeg' onChange={(e)=>{
                              setfile(e.target.files[0]);
                         }} style={{marginTop: '10px'}}/>
                    </div> 

                    {/* Help */}
                    <h4 style={{marginTop: '15px'}}>Help</h4>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         Help desk link <br />
                              <input value={formData.helpLink} type="url" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   helpLink: e.target.value
                              })} placeholder="Link..." />
                         </div>
                    </div>
                    Help video
                    <div style={{width: '500px'}}>
                         <input name='file' type='file' accept='video/*' onChange={(e)=>{
                              setvideo(e.target.files[0]);
                         }} style={{marginTop: '10px'}}/>
                    </div> 

                    {/* Other */}
                    <h4 style={{marginTop: '15px'}}>Other</h4>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         Privacy policy <br />
                              <input value={formData.privacyPolicy} type="url" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   privacyPolicy: e.target.value
                              })} placeholder="Link..." />
                         </div>
                    </div>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         Terms conditions <br />
                              <input value={formData.termsConditions} type="url" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   termsConditions: e.target.value
                              })} placeholder="Link..." />
                         </div>
                    </div>

                    <button onClick={onSubmit} className="badge badge-gradient-success"  style={{
                         width: '440px', display: 'flex', justifyContent: 'center',
                         border: 'none', borderRadius: '5px', fontSize: '16px',
                         padding: '10px 0', cursor: isSending ? 'wait' : 'pointer' ,
                         marginTop: '20px'
                    }}>Save</button>
               </div>
          </div>
     );
}
export default Setting;