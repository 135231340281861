import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {ArrowBackIosOutlined, ArrowForwardIosRounded} from '@material-ui/icons'
import conf from '../../conf'
import BannerItem from '../items/BannerItem';


const UploadBanner = ()=>{
     const [isSending, setIsSending] = useState(false)
     const [message, setMessage] = useState("")
     const [formData, setFormData] = useState({
          link: '',
          type: 'ad',
     });

     const showToast = (mes)=>{
          setMessage(mes)
     }

     const isValid = () => {
          if(!formData.link.startsWith("http")){
               showToast("Please enter valid url");
               return false;
          }
          else if(!formData.file){
               showToast("Please choose banner");
               return false;
          }
          return true;
     }

     const onSubmit = async ()=>{

          if(!isValid()) return;

          setIsSending(true)
          try {
               const rBody = new FormData();
               rBody.append('link', formData.link)
               rBody.append('type', formData.type)
               rBody.append('file', formData.file)
               const res = await axios.post(conf.serverAddress + "admin/upload-banner", rBody, 
                    {headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
               }})
               console.log(res);
               setFormData({
                    link: '',
                    file: null
               })
               setIsSending(false)
               onPageChange(0, true);
          } catch (error) {
               console.log(error);
               setIsSending(false)
               setFormData({
                    link: ''
               })
          }
     }

     const onChangeForm = (event)=>{
          setMessage("")
          const { value, name } = event.target;
          setFormData(pVal => {
               if(name != 'file'){
                    return {
                         ...pVal,
                         [name]: value
                    };
               }
               else{
                    return {
                         ...pVal,
                         file: event.target.files[0]
                    };
               }
          })
          console.log(formData);
     }

     const [isNoMoreBanners, setNoMoreBanners] = useState(false);
     const [page, setPageIndex] = useState(0);
     const [banners, setBanners] = useState([]);
     const [messagex, setMessageX] = useState("Loading...");

     useEffect( async ()=>{ 
          onPageChange(0, true);
     }, []);

     const onPageChange = async (index, isNext)=>{
          try {
               setMessageX('Loading...')
               const params = new URLSearchParams();
               params.append("skip", 20 * index);
               params.append("limit", 20);
               const res = await axios.get(conf.serverAddress + "admin/banner", 
               {params: params, headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}});
               if(res.data.length != 0){
                    setBanners(res.data);
                    setMessageX('')
                    setNoMoreBanners(false)
                    if(isNext){
                         setPageIndex(index);
                    }
                    else{
                         setPageIndex(index);
                    }
               }    
               else{
                    setMessageX('Empty!');
               } 
               if(res.data.length < 20){
                    setNoMoreBanners(true);
               }
          } 
          catch (error) {
               setMessageX('Failed! try again')
          }
     }

     const buttonStyle = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px',
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)"};

     const buttonStyleDesable = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px', opacity: 0.2,
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)", };

     return(
          <>
               <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
               }}>
                    <h5 style={{
                         display: message ? "block" : 'none',
                         color: 'red'
                    }}>{message}</h5>
                    <h3 style={{width: '500px', marginBottom: '20px'}}>Upload banner</h3>
                    <input name='link' onChange={onChangeForm} style={{ width:'500px', border: 'none', padding: '10px 20px', borderRadius: '5px', marginTop: '15px' }} 
                         placeholder="link..." 
                         value={formData.link}/>
                    <div style={{width: '500px', marginBottom: '10px', marginTop: '10px'}}>
                    Type 
                    <select onChange={onChangeForm} name="type" value={formData.type} style={{border: 'none', borderRadius: "5px", padding: "6px 10px", marginLeft: '10px'}}>
                              <option value="ad">Ad</option>
                              <option value="game">Game</option>
                    </select> 
                    </div>     
                    <h5 style={{width: '500px', marginTop: '10px'}}>Image</h5>
                    <div style={{width: '500px'}}>
                         { formData.file ? <div  style={{width: '200px', height: '100px', background: '#cdd3d4', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                   <img src={URL.createObjectURL(formData.file)} style={{maxWidth: '200px', height: '100px'}}/> 
                              </div> : 
                         <div style={{width: '200px', height: '100px', background: '#cdd3d4', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              Image
                         </div> }
                         <input name='file' type='file' accept='image/*' onChange={onChangeForm} style={{marginTop: '10px'}}/>
                    </div>    
                    <button onClick={onSubmit} className="badge badge-gradient-success"  style={{
                              width: '500px', display: 'flex', justifyContent: 'center',
                              border: 'none', borderRadius: '5px', margin: '15px 0', fontSize: '16px',
                              padding: '10px 0', cursor: isSending ? 'wait' : 'pointer' 
                         }}>Upload</button>
               </div>
               <div>
               <div className="row" style={{marginTop: "15px"}}>
                    <div className="col-12 grid-margin">
                         <div className="card">
                              <div className="card-body">
                                   {/* <h4 className="card-title">Recent Tickets</h4> */}
                                   <div className="table-responsive">
                                        <table className="table">
                                             <thead>
                                                  <tr>
                                                       <th>No</th>
                                                       <th>Banner</th>
                                                       <th>Link</th>
                                                       <th>Type</th>
                                                       <th>Visible</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {
                                                       messagex === "" ? banners.map((arr, i) => <BannerItem id={((page*20)+i)+1} ele={arr}/>) : null
                                                  }
                                             </tbody>
                                        </table>
                                        {
                                             messagex === "" ? null : <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  margin: '100px 0'
                                             }}>{messagex}</div>
                                        }
                                        <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'end',
                                                  padding: '10px',
                                             }}>
                                                  <div 
                                                       style={{
                                                            borderRadius: '5px',
                                                            // border: '1px solid red'
                                                       }}>
                                                       <ArrowBackIosOutlined className=" bg-gradient-primary" 
                                                            style={page !== 0 ? buttonStyle : buttonStyleDesable}
                                                            onClick={()=>{
                                                                 if(page > 0){
                                                                      onPageChange(page - 1, false)
                                                                 }
                                                            }}
                                                       />
                                                       <span style={{margin: "0 15px"}}>{page + 1}</span>
                                                       <ArrowForwardIosRounded className=" bg-gradient-primary"
                                                            style={isNoMoreBanners ? buttonStyleDesable : buttonStyle}
                                                            onClick={()=>{
                                                                 if(!isNoMoreBanners){
                                                                      onPageChange(page + 1, true)
                                                                 }
                                                            }}
                                                            />
                                                  </div>
                                             </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               </div>
          </>
     );
}
export default UploadBanner;