import React from "react";
import './App.css'
import Deshboard from "./components/dashboard/Deshboard";
import { Routes, Route } from 'react-router-dom'
import Home from "./components/home/Home";
import Notification from "./components/notification/Notification";
import Login from "./components/login/Login";
import PaymentHistory from "./components/payment_history/PaymentHistory";
import UserWithdrawalHistory from "./components/user_withdrawal_history/UserWithdrawalHistory";
import Network from "./components/network/Network";
import UploadBanner from "./components/banner/UploadBanner";
import Setting from "./components/setting/Setting";
import WithdrawalHistroy from "./components/withdrawal_history/WithdrawalHistory";
import UserPage from "./components/user_page/UserPage";

const App = ()=>{
     const admin = document.cookie.split("=")[0];
     if(admin === 'authx'){
          return(
               <>
                    <Routes>
                         <Route path="/admin-login" element={<Login/>}/>
                         <Route path="/admin-dashboard" element={<Home><Deshboard/></Home>}/>
                         <Route path="/admin-notification" element={<Home><Notification/></Home>}/>
                         <Route path="/admin-payment-history" element={<Home><PaymentHistory/></Home>}/>
                         <Route path="/admin-user-withdrawal-history" element={<Home><UserWithdrawalHistory/></Home>}/>
                         <Route path="/admin-withdrawal-history" element={<Home><WithdrawalHistroy/></Home>}/>
                         <Route path="/admin-user-network" element={<Home><Network/></Home>}/>
                         <Route path="/admin-user-data" element={<Home><UserPage/></Home>}/>
                         <Route path="/admin-upload-banner" element={<Home><UploadBanner/></Home>}/>
                         <Route path="/admin-setting" element={<Home><Setting/></Home>}/>
                         <Route path='*' element={<h1>Page not found</h1>}/>
                    </Routes>
               </>
          );
     }
     else{
          return(
               <>
                    <Routes>
                         <Route path="/admin-login" element={<Login/>}/>
                         <Route path="/admin-dashboard" element={<Login/>}/>
                         <Route path="/admin-notification" element={<Login/>}/>
                         <Route path="/admin-payment-history" element={<Login/>}/>
                         <Route path="/admin-user-withdrawal-history" element={<Login/>}/>
                         <Route path="/admin-user-network" element={<Login/>}/>
                         <Route path="/admin-upload-banner" element={<Login/>}/>
                         <Route path="/admin-user-data" element={<Login/>}/>
                         <Route path="/admin-withdrawal-history" element={<Login/>}/>
                         <Route path="/admin-setting" element={<Login/>}/>
                         <Route path='*' element={<h1>Page not found</h1>}/>
                    </Routes>
               </>
          );
     }
}

export default App;