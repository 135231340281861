import React from "react";
import { useNavigate } from "react-router-dom";

const UserDataItem = (props)=>{
     
     const actionEnable = props.actionEnable;
     const {profilePhoto, name, accountStatus, email, balance, coins, mobileNumber, totalChilds, pendingChilds, _id} = props.ele;
     const go = useNavigate();
     const viewWithdrawalHistory = ()=>{
          go("/admin-user-withdrawal-history?id=" + _id);
     }
     const viewNetwork = ()=>{
          go("/admin-user-network?id=" + _id);
     }

     const getStatusElement = ()=>{
          switch(accountStatus){
               case "active":
                    return <label className="badge badge-gradient-success">Active</label>;
               case "locked":
                    return <label className="badge badge-gradient-danger">Locked</label>;
               case "blocked":
                    return <label className="badge badge-gradient-danger">Blocked</label>;
          }
     }
     
     return(
          <tr>
               <td>{props.id}</td>
               <td>
                   <img src={profilePhoto ? profilePhoto : "assets/images/faces/user.png"} className="mr-2" alt="pic"/>
                   {actionEnable ?  <a className="ax" href={"admin-user-data?id="+_id}>{name}</a> : name}
               </td>
               <td>{ "₹" + Number(balance).toFixed(2) }</td>
               <td>{coins}</td>
               <td>{getStatusElement()}</td>
               <td>{totalChilds}</td>
               <td>{pendingChilds}</td>
               <td>+91-{mobileNumber}</td>
               <td>{email}</td>
               {
                    actionEnable ? <>
                         <td><label onClick={viewNetwork} className="badge badge-gradient-success" style={{cursor: 'pointer'}}>VIEW</label></td>
                         <td><label onClick={viewWithdrawalHistory} className="badge badge-gradient-danger" style={{cursor: 'pointer'}}>VIEW</label></td>
                    </> : null
               } 
          </tr>
     );
}

export default UserDataItem;