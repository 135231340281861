import React, {useState} from "react";
import {Switch} from '@mui/material'
import axios from 'axios';
import conf from "../../conf";

const BannerItem = (props)=>{
     const { imageUrl, link, type, enable, _id } = props.ele;

     const [isEnable, setEnable] = useState(enable);
     
     const onChange = async (isChecked) =>{
          try {
               const res = await axios.patch(conf.serverAddress + "admin/banner", 
                              {
                                   id: _id,
                                   enable: isChecked
                              },
                              {
                                   headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
                              });
               setEnable(res.data.isEnable);
          } catch (err) {}
          setEnable(isChecked);
     }

     return(
          <tr>
               <td>{props.id}</td>
               <td>
                   <img src={imageUrl ? imageUrl : "user.png"} alt="banner" style={{
                        borderRadius: '0'
                   }}/>
               </td>
               <td><a href={link} target="_blank">{link}</a></td>
               <td>{type ? type.toUpperCase() : "NONE"}</td>
               <td>{<Switch checked={isEnable} onChange={(e, is)=>onChange(is)} />}</td>
          </tr>
     );
}

export default BannerItem;